import store from "@/state/store";

export default [
  {
    path: "*",
    component: () => import("../views/pages/utility/404.vue"),
  },
  {
    path: "/404-notfound",
    component: () => import("../views/pages/utility/404.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["account/isLoggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("account/logout");
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
          // route.push('/login')
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "home",
              }
            : {
                ...routeFrom,
              }
        );
      },
    },
    // component: () => import("../views/pages/account/logout"),
  },
  // {
  //   path: "/logout",
  //   name: "logout",
  //   meta: {
  //     authRequired: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       store.dispatch("authfack/logout");
  //       const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
  //         route.push("/login")
  //       );
  //       // Navigate back to previous page, or home as a fallback
  //       next(
  //         authRequiredOnPreviousRoute
  //           ? {
  //               name: "home",
  //             }
  //           : {
  //               ...routeFrom,
  //             }
  //       );
  //     },
  //   },
  // },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    // component: () => import("../views/pages/test/parent"),
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/profile",
    name: "Data Saya",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/account/me"),
  },
  {
    path: "/penjualan",
    name: "List Order",
    meta: {
      authRequired: true,
    },
    // component: () => import("../views/pages/order/detail"),
    component: () => import("../views/pages/order/index"),
  },
  {
    path: "/penjualan/tambah",
    name: "Tambah Order",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/order/form"),
    // component: () => import("../views/pages/test/tambah"),
  },
  {
    path: "/penjualan/:id",
    name: "Detail Order",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/order/detail"),
    // component: () => import("../views/pages/test/tambah"),
  },
  {
    path: "/pemesanan/print",
    name: "CetakOrder",
    meta: {
      authRequired: true,
    },
    // component: () => import("../views/pages/pemesanan/orders"),
  },

  {
    path: "/pembelian",
    name: "List Pembelian",
    meta: {
      authRequired: true,
    },
    // component: () => import("../views/pages/Pembelian/detail"),
    component: () => import("../views/pages/purchase/index"),
  },
  {
    path: "/pembelian/tambah",
    name: "Tambah Pembelian",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/purchase/form"),
    // component: () => import("../views/pages/test/tambah"),
  },
  {
    path: "/pembelian/:id",
    name: "Detail Pembelian",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/purchase/detail"),
    // component: () => import("../views/pages/test/tambah"),
  },
  {
    path: "/pemesanan/print",
    name: "CetakPembelian",
    meta: {
      authRequired: true,
    },
    // component: () => import("../views/pages/pemesanan/orders"),
  },

  // ROUTES CLOTH CATEGORY
  {
    path: "/produk/kategori",
    name: "Data Kategori",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-category/index"),
  },
  {
    path: "/produk/min-stok",
    name: "Edit Min. Stock",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/min-stock/index"),
  },
  {
    path: "/produk/kategori/:id/edit",
    name: "Ubah Data Kategori",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-category/form"),
  },
  {
    path: "/produk/kategori/tambah",
    name: "Tambah Data Kategori",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-category/form"),
  },
  {
    path: "/produk/kategori/:id",
    name: "Detail Kategori",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-category/detail"),
  },
  // END ROUTES CLOTH CATEGORY

  // ROUTES CLOTH PRICE TYPE
  {
    path: "/produk/tipe-harga",
    name: "Data Tipe Harga",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-price-type/index"),
  },
  {
    path: "/produk/tipe-harga/:id/edit",
    name: "Edit Tipe Harga",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-price-type/form"),
  },
  {
    path: "/produk/tipe-harga/tambah",
    name: "Tambah Tipe Harga",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-price-type/form"),
  },
  {
    path: "/produk/tipe-harga/:id",
    name: "Detail Tipe Harga",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth-price-type/detail"),
  },

  // END ROUTES CLOTH PRICE TYPE

  // ROUTES COLOR
  {
    path: "/produk/warna",
    name: "Data Warna",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/color/index"),
  },
  {
    path: "/produk/warna/tambah",
    name: "Tambah Warna",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/color/form"),
  },
  {
    path: "/produk/warna/:id",
    name: "Detail Warna",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/color/detail"),
  },
  {
    path: "/produk/warna/:id/edit",
    name: "Edit Warna Produk",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/color/form"),
  },
  // END ROUTES COLOR

  // ROUTES SIZE
  {
    path: "/produk/ukuran",
    name: "Data Ukuran",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/size/index"),
  },
  {
    path: "/produk/ukuran/tambah",
    name: "Tambah Ukuran",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/size/form"),
  },
  {
    path: "/produk/ukuran/:id",
    name: "Detail Ukuran",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/size/detail"),
  },
  {
    path: "/produk/ukuran/:id/edit",
    name: "Edit Ukuran",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/size/form"),
  },
  // END ROUTES SIZE

  // ROUTES CLOTH
  {
    path: "/produk",
    name: "Data Pakaian",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth/index"),
  },
  {
    path: "/produk/tambah",
    name: "Tambah Pakaian",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth/form"),
  },
  {
    path: "/produk/:id",
    name: "Detail Pakaian",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/cloth/detail"),
  },
  //END ROUTES CLOTH

  {
    path: "/stok/transaksi",
    name: "Transaksi",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/stock/transaction"),
  },
  {
    path: "/stok/transaksi/:id",
    name: "Detail Stok",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/stock/detail"),
  },
  // {
  //   path: "/stok/tambah",
  //   name: "Tambah Stok",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("../views/pages/stock/form"),
  // },
  {
    path: "/stok/penyesuaian",
    name: "Penyesuaian",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/stock/adjustment"),
  },

  // ROUTES SUPPLIER
  {
    path: "/supplier",
    name: "List Supplier",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/supplier/index"),
  },
  {
    path: "/supplier/tambah",
    name: "Tambah Supplier",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/supplier/form"),
  },
  {
    path: "/supplier/:id",
    name: "Detail Supplier",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/supplier/detail"),
  },
  {
    path: "/supplier/:id/edit",
    name: "Edit Supplier",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/supplier/form"),
  },
  // END ROUTES SUPPLIER

  // ROUTES KATEGORI CUSTOMER
  {
    path: "/pelanggan/kategori",
    name: "Data Kategori Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer-category/index"),
  },
  {
    path: "/pelanggan/kategori/tambah",
    name: "Tambah Kategori Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer-category/form"),
  },
  {
    path: "/pelanggan/kategori/:id",
    name: "Detail Kategori Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer-category/detail"),
  },
  {
    path: "/pelanggan/kategori/:id/edit",
    name: "Edit Kategori Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer-category/form"),
  },
  // END ROUTES KATEGORI CUSTOMER

  // ROUTES CUSTOMER
  {
    path: "/pelanggan",
    name: "List Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/index"),
  },
  {
    path: "/pelanggan/tambah",
    name: "Tambah Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/form"),
  },
  {
    path: "/pelanggan/:id",
    name: "Detail Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/detail"),
  },
  {
    path: "/pelanggan/:id/edit",
    name: "Edit Pelanggan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/form"),
  },
  // END ROUTES CUSTOMER

  // ROUTES USER
  {
    path: "/pengguna",
    name: "List User",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user/index"),
  },
  {
    path: "/pengguna/tambah",
    name: "Tambah User",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user/form"),
  },
  {
    path: "/pengguna/:id",
    name: "Detail User",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user/detail"),
  },
  {
    path: "/pengguna/:id/edit",
    name: "Edit User",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user/form"),
  },
  // END ROUTES USER

  // ROUTES REPORT
  {
    path: "/laporan/penjualan",
    name: "LaporanPenjualan",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/report/order"),
  },
  {
    path: "/laporan/pembelian",
    name: "LaporanPembelian",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/report/purchase"),
  },
  {
    path: "/laporan/barang-terlaris",
    name: "Barang Terlaris",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/report/bestseller"),
  },

  // END ROUTES REPORT
];
