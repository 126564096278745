import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";

import vco from "v-click-outside";
import VueApexCharts from "vue-apexcharts";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSlideBar from "vue-slide-bar";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
import store from "@/state/store";
import ElementUI from "element-ui";
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import "./assets/css/theme/index.css";
// import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import infiniteScroll from "vue-infinite-scroll";
import Vue2TouchEvents from "vue2-touch-events";

import App from "./App.vue";
// As a plugin
import VueMask from "v-mask";
Vue.config.productionTip = false;

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from "vue-easy-lightbox";

Vue.prototype.$http = axios;
Vue.use(ElementUI);
locale.use(lang);
Vue.use(Lightbox);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});

import { initFirebaseBackend } from "./helpers/firebase/authUtils";

import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}

import "@/assets/scss/app.scss";

Vue.component("VueSlideBar", VueSlideBar);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.component("apexchart", VueApexCharts);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(infiniteScroll);
Vue.use(Vue2TouchEvents);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
