import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

export const state = {
  type: null,
  message: null,
};
import { serializeErrorMessage } from "@/_helpers";

export const mutations = {
  success(state, message) {
    state.type = "success";
    state.message = serializeErrorMessage(message);
  },
  info(state, message) {
    state.type = "info";
    state.message = message;
  },
  error(state, message) {
    state.type = "danger";
    state.message = serializeErrorMessage(message);
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
};

export const actions = {
  success({ commit }, message) {
    ElementUI.Notification.success({
      title: "Success",
      dangerouslyUseHTMLString: true,
      message: serializeErrorMessage(message),
      position: "bottom-right",
      duration: 7000,
    });
    commit("success", message);
  },
  error({ commit }, message) {
    ElementUI.Notification.error({
      title: "Error",
      dangerouslyUseHTMLString: true,
      message: serializeErrorMessage(message),
      position: "bottom-right",
      duration: 7000,
    });
    commit("error", message);
  },
  clear({ commit }) {
    commit("clear");
  },
};
